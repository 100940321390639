import type { IdentityProviderType } from '@b-stock/account-api-client'

export default Object.freeze({
  SIGN_IN_SUBMIT_EMAIL: 'SIGN_IN_SUBMIT_EMAIL',
  SIGN_IN_SUBMIT_EMAIL_PENDING: 'SIGN_IN_SUBMIT_EMAIL_PENDING',
  SIGN_IN_SUBMIT_EMAIL_FULFILLED: 'SIGN_IN_SUBMIT_EMAIL_FULFILLED',
  SIGN_IN_SUBMIT_EMAIL_REJECTED: 'SIGN_IN_SUBMIT_EMAIL_REJECTED',
  SIGN_IN_SUBMIT_PASSWORD: 'SIGN_IN_SUBMIT_PASSWORD',
  SIGN_IN_SUBMIT_PASSWORD_PENDING: 'SIGN_IN_SUBMIT_PASSWORD_PENDING',
  SIGN_IN_SUBMIT_PASSWORD_FULFILLED: 'SIGN_IN_SUBMIT_PASSWORD_FULFILLED',
  SIGN_IN_SUBMIT_PASSWORD_REJECTED: 'SIGN_IN_SUBMIT_PASSWORD_REJECTED',
})

export type SubmitEmailValues = {
  email: string
  identityProviderType?: IdentityProviderType
  isEnterpriseUser: boolean
  isShellAccount?: boolean
  redirectAfterLogin?: string
  siteAbb?: string
  subflow?: string
}
