import { createGlobalStyle } from 'styled-components'

import { Colors } from '@b-stock/bstock-react/design-system'

const GlobalStyle = createGlobalStyle`

  #app {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
  }

  html {
    font-size: 16px;
    line-height: 1.5rem;
  }

  body {
    font-size: 16px;
    position: relative;
    min-height: 100%;
  }

  img {
    max-height: fit-content;
  }

  center, table, p, ul, ol {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  /* table styling */
  table {
    border: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
    border-collapse: collapse;
  }

  th {
    font-weight: normal;
    padding: 0.7rem;
    background: ${Colors.Semantic.Neutral.medium_grey};
  }

  td {
    vertical-align: baseline;
    padding: 0.7rem;
    font-weight: bold;
  }

  p {
    font-weight: normal;
  }
`

export default GlobalStyle
