import { put, takeLatest } from 'redux-saga/effects'

import { ddLogger } from '@b-stock/bstock-react'

import { authTypes } from '@store/types'

import types from './types'

export function* handleAuthLoaded() {
  try {
    yield put({ type: types.INIT_LOAD_FULFILLED })
  } catch (error) {
    ddLogger.error('Auth loaded error', {}, error as Error)
  }
}

export default function* uiSaga() {
  yield takeLatest(authTypes.login.AUTH_LOADED, handleAuthLoaded)
}
