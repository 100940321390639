import type { AxiosResponse } from 'axios'

import type {
  Role,
  RoleHistory,
  UpdateRoleDto,
  UpdateRoleStatusDto,
} from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, post, put } from '@helpers/xhr'

const { api } = appConfig

const url = api.accounts.id.roles.accountsIdRole.default

export default {
  load: ({
    id,
    role,
  }: {
    id: string
    role: string
  }): Promise<AxiosResponse<Role>> =>
    get(url, null, { entities: { id, role } }),

  create: ({
    id,
    role,
    data,
  }: {
    id: string
    role: string
    data: UpdateRoleStatusDto
  }): Promise<AxiosResponse<RoleHistory>> =>
    post(api.accounts.id.roles.accountsIdRole.status, data, {
      entities: { id, role },
    }),

  update: ({
    id,
    role,
    data,
  }: {
    id: string
    role: string
    data: UpdateRoleDto
  }): Promise<AxiosResponse<Role>> =>
    put(url, data, { entities: { id, role } }),
}
