import get from 'lodash-es/get'
import type { Reducer, AnyAction } from 'redux'

const defaultParse = (v: unknown) => v || {}

type AsyncTypes = {
  pending?: string
  fulfilled?: string
  rejected?: string
  reset?: string
}

type AsyncReducerOpts<State> = {
  types: AsyncTypes
  onPending?: (payload: any, state: State) => Partial<State>
  parsePayload?: (payload: any, state: State) => Partial<State>
  parseError?: (error: any, state: State) => Partial<State>
  parseReset?: (payload: any, state: State) => Partial<State>
}

type MapOfFunctions<State> = {
  [index: string]: (state: State, action: AnyAction) => State
}

export type AsyncReducerError = {
  message?: any
  code?: any
}

export type AsyncReducerState = {
  error: AsyncReducerError | null
  loading: boolean
  success: boolean | null
}

export const INITIAL_ASYNC_REDUCER_STATE = {
  error: null,
  loading: false,
  success: null,
}

export const asyncReducer = <State extends AsyncReducerState>({
  types,
  onPending = defaultParse,
  parsePayload = defaultParse,
  parseError = defaultParse,
  parseReset = defaultParse,
}: AsyncReducerOpts<State>): MapOfFunctions<State> => {
  const reducer: MapOfFunctions<State> = {}

  if (types.pending) {
    reducer[types.pending] = (state: State, { payload }: any = {}) => ({
      ...state,
      error: null,
      loading: true,
      ...onPending(payload, state),
    })
  }

  if (types.fulfilled) {
    reducer[types.fulfilled] = (state: State, { payload }: any = {}) => ({
      ...state,
      ...parsePayload(payload, state),
      error: null,
      loading: false,
      success: true,
    })
  }

  if (types.rejected) {
    reducer[types.rejected] = (state: State, error: any) => ({
      ...state,
      loading: false,
      error: {
        message: get(error, 'error.response.data.errorMessage', error?.message),
        code: get(error, 'error.response.status', null),
      },
      ...parseError(error, state),
    })
  }

  if (types.reset) {
    reducer[types.reset] = (state: State, { payload }: any = {}) => ({
      ...state,
      loading: false,
      error: null,
      success: null,
      ...parseReset(payload, state),
    })
  }

  return reducer
}

const makeReducer = <State>(
  initialState: State,
  mapOfFunctions: MapOfFunctions<State>
) => {
  const reducer: Reducer<State> = (state, action) => {
    if (state === undefined) {
      return initialState
    } else if (mapOfFunctions[action.type]) {
      return mapOfFunctions[action.type](state, action)
    }
    return state
  }
  return reducer
}
export default makeReducer
