import { combineReducers } from 'redux'

import changePassword from './changePassword/reducer'
import login from './login/reducer'
import signin from './signIn/reducer'
import signup from './signUp/reducer'

export default combineReducers({
  login,
  changePassword,
  signin,
  signup,
})
