import Components from '../Components'
import type { RouteDefinitions } from '../Router'

const addressBookRoutes = {
  'address-book': {
    path: '/settings/business-profile/:accountId/address-book',
    exact: true,
    component: <Components.AddressBook />,
    requireAuth: true,
  },
  'address-book-details': {
    path: '/settings/business-profile/:accountId/address-book/:id',
    exact: true,
    component: <Components.AddressBookDetailsPage />,
    requireAuth: true,
  },
} as const satisfies RouteDefinitions

export default addressBookRoutes
