import common from './common.json'
import modules from './modules'
import months from './months/months.json'

const en = {
  ...months,
  ...common,
  ...modules,
}

export default en
