import appConfig from '@config/config'
import { get, post, put } from '@helpers/xhr'

const url = appConfig.api.docserv.default

const buildForm = (doc: Blob) => {
  const formData = new FormData()
  formData.append('doc', doc)
  formData.append('type', doc.type)
  return formData
}

export default {
  create: (doc: Blob) =>
    post<any>(url, buildForm(doc), {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  update: (doc: Blob) =>
    put<any>(url, buildForm(doc), {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  downloadByDocId: (id: string) =>
    get<any>(`${url}/${id}`, undefined, { responseType: 'blob' }),
}
