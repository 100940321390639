import type { ErrorInfo } from 'react'

import { ddLogger } from '@b-stock/bstock-react'

import useUserInfo from '@helpers/useUserInfo'

export const useLogError = () => {
  const { accountId, personId } = useUserInfo()

  const logError = (error: Error, info: ErrorInfo) => {
    const additionalProps: { [key: string]: any } = {
      ...(accountId && { accountId }),
      ...(personId && { personId }),
    }

    ddLogger.error(
      'Caught error at react error boundary',
      { info, ...additionalProps },
      error
    )
  }

  return logError
}
