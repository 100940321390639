import { ddLogger } from '@b-stock/bstock-react'
import type {
  PreferencesDto,
  ChannelSettingsChannelEnum as Channel,
} from '@b-stock/notification-api-client'

import appConfig from '@config/config'
import { patch, get } from '@helpers/xhr'

const { api: apiConfig } = appConfig

let emailNotificationType = apiConfig.notifications.marketingTypeId // to be populated only once and then reused everytime

/** contact notification svc to update marketing preference(s) without care of response or failure
 * Authorization header is auto set by CookieManager.getCookie('bstock_access_token')
 */
export default {
  updateMarketingPreference: async (
    acceptMarketingSolicitation: boolean,
    { personId }: { personId: string }
  ) => {
    const baseUrl = apiConfig.notifications.preferences
    const marketingTypeId = await getEmailNotificationTypeId()
    if (marketingTypeId) {
      const url = `${baseUrl}/${personId}/type/${marketingTypeId}`
      const data = [
        {
          type: 'EMAIL',
          active: acceptMarketingSolicitation,
        },
      ]
      try {
        const result: any = await patch(url, data)
        ddLogger.info(
          `Marketing preference updated, personId: ${personId}, response: ${JSON.stringify(
            result.data || result.status
          )}`
        )
      } catch (err) {
        ddLogger.error(
          `Marketing preference update failed, personId: ${personId}, error: ${JSON.stringify(err)}`
        )
      }
    } else {
      ddLogger.error(
        `No marketing type id found, personId: ${personId}-${marketingTypeId}. Emails has not been sent`
      )
    }
  },

  updateNotificationPreference: async (
    personId: string,
    typeId: string,
    channel: Channel,
    active: boolean
  ): Promise<void> => {
    const baseUrl = apiConfig.notifications.preferences
    const url = `${baseUrl}/${personId}/type/${typeId}`

    await patch(url, [{ type: channel, active }])
  },
  fetchNotificationPreferences: async (personId: string) => {
    const { data } = await get<PreferencesDto[]>(
      `${apiConfig.notifications.preferences}/:personId`,
      null,
      { entities: { personId } }
    )

    return data
  },
}

// used when process.env.NOTIFICATION_TYPE_ID not defined. Helpful local developments and new servers
async function getEmailNotificationTypeId() {
  try {
    // may have been loaded by environment variable
    if (emailNotificationType) {
      return emailNotificationType
    }

    const emailTypeName = apiConfig.notifications.marketingEmailTypeName

    const baseUrl = apiConfig.notifications.types
    const url = `${baseUrl}?_type=${emailTypeName}`
    const results = await get<any>(url)
    // In local development we may not have the type in the database
    const type = results.data?.types?.[0]?._id
    if (type) {
      // store result to always be used again
      emailNotificationType = type
      return emailNotificationType
    }
    return null
  } catch (error) {
    ddLogger.error('Error fetching email notification type id', { error })
    return null
  }
}
