import gPhoneNumber from 'google-libphonenumber'
import get from 'lodash-es/get'

import { countries } from '@b-stock/bstock-react'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

export type Country = typeof countries
export type CountryKey = keyof Country

export type PhoneCountry = {
  name: string
  code: string | number
  abbr: CountryKey
}

export type PhoneStringValue = string | undefined

export type PhoneValue = [PhoneCountry | undefined, PhoneStringValue]

export const getCountryValue = (abbr: string) =>
  abbr
    ? { ...get(countries, abbr.toLowerCase()), abbr: abbr.toUpperCase() }
    : undefined

export const defaultPhoneValue: PhoneValue = [getCountryValue('us'), '']

const getCountryByNumber = (number: PhoneStringValue) => {
  const parsedValue = phoneUtil.parseAndKeepRawInput(`+${number}`)
  const abbr = phoneUtil.getRegionCodeForNumber(parsedValue)
  return getCountryValue(abbr)
}

const toValue = (
  number: PhoneStringValue,
  country?: PhoneCountry
): PhoneValue => {
  if (!number) {
    return defaultPhoneValue
  }
  const isPrefixed = number[0] === '+'

  const parsedCountry = country || getCountryByNumber(number)
  const sliceIndex =
    `${parsedCountry?.code || ''}`.length + (isPrefixed ? 1 : 0)
  return [parsedCountry, number.slice(sliceIndex)]
}

const toString = (value: PhoneValue): PhoneStringValue => {
  try {
    const domestic = value[1]
    if (domestic) {
      const code = get(value, '0.code', '')
      return `+${code}${domestic}`.replace(/\s+/g, '')
    }
  } catch {
    // Ignore
  }
}

export const isPhoneValue = (value: unknown[]): value is PhoneValue => {
  if (value.length !== 2) {
    return false
  }

  const [cc, natNum] = value

  if (!(natNum === undefined || typeof natNum === 'string')) {
    return false
  }

  if (!(cc === undefined || typeof cc === 'object')) {
    return false
  }

  return true
}

export const isValidPhoneValue = (value?: unknown[]): boolean => {
  try {
    if (!value || !value[1]) {
      // We consider an empty or partial value to be valid, which might seem
      // strange, but the idea is we want "is this number well formatted" and
      // "has a number been supplied at all" to be different checks with
      // different downstream messaging.
      return true
    }

    if (!isPhoneValue(value)) {
      return false
    }

    if (!/^\d+$/.test(value[1])) {
      return false
    }

    const phone = phoneUtil.parse(toString(value))

    if (!phoneUtil.isPossibleNumber(phone)) {
      return false
    }

    const gotCountry = getCountryByNumber(toString(value))

    if (!gotCountry || gotCountry.abbr !== value[0]?.abbr) {
      return false
    }

    return true
  } catch {
    return false
  }
}

export const format = (phoneNumberStr: string): string | undefined => {
  try {
    const parsedValue = phoneUtil.parseAndKeepRawInput(`+${phoneNumberStr}`)
    return phoneUtil.format(
      parsedValue,
      gPhoneNumber.PhoneNumberFormat.NATIONAL
    )
  } catch {
    // Ignore
  }
}

export default {
  toValue,
  toString,
}
