import type { History } from 'history'

import type { CheckEmailFulfilledPayload } from './reducers/checkEmail'
import type { SubmitEmailFulfilledPayload } from './reducers/submitEmail'
import types from './types'

export type CheckEmailActionPayload = {
  email: string
}
export const checkEmailAction = (payload: CheckEmailActionPayload) => ({
  type: types.CHECK_EMAIL,
  payload,
})
export const checkEmailPending = () => ({
  type: types.CHECK_EMAIL_PENDING,
})
export const checkEmailFulfilled = (payload: CheckEmailFulfilledPayload) => ({
  type: types.CHECK_EMAIL_FULFILLED,
  payload,
})
export const checkEmailRejected = (error: any) => ({
  type: types.CHECK_EMAIL_REJECTED,
  error,
})

export const setSignUpEmail = (signUpEmail: string) => ({
  type: types.SET_SIGN_UP_EMAIL,
  signUpEmail,
})

export type FetchVerificationCodeActionPayload = {
  id: string
  email?: string
  formatMessage?: (props: any, options?: any) => string
  exists?: boolean
  hasCredentials?: boolean
  isEmailVerified?: boolean
}
export const fetchVerificationCodeAction = (
  payload: FetchVerificationCodeActionPayload
) => ({
  type: types.FETCH_VERIFICATION_CODE,
  payload,
})
export const fetchVerificationCodePending = () => ({
  type: types.FETCH_VERIFICATION_CODE_PENDING,
})
export const fetchVerificationCodeFulfilled = () => ({
  type: types.FETCH_VERIFICATION_CODE_FULFILLED,
})
export const fetchVerificationCodeRejected = (error: any) => ({
  type: types.FETCH_VERIFICATION_CODE_REJECTED,
  error,
})

export type SubmitEmailActionPayload = {
  email: string
  id?: string
}
export const submitEmailAction = (payload: SubmitEmailActionPayload) => ({
  type: types.SUBMIT_EMAIL,
  payload,
})
export const submitEmailPending = () => ({
  type: types.SUBMIT_EMAIL_PENDING,
})
export const submitEmailFulfilled = (payload: SubmitEmailFulfilledPayload) => ({
  type: types.SUBMIT_EMAIL_FULFILLED,
  payload,
})
export const submitEmailRejected = (error: any) => ({
  type: types.SUBMIT_EMAIL_REJECTED,
  error,
})

export type SetPasswordActionPayload = {
  email: string
  verificationCode: string
  acceptMarketingSolicitation: boolean
  country: string
  brid: string
  firstName: string
  lastName: string
  phone?: string
  password: string
  id: string
  redirectAfterSignup: string
  siteAbb: string
  subflow: string
}
export const setPasswordAction = (
  payload: SetPasswordActionPayload,
  history: History
) => ({
  type: types.SET_PASSWORD,
  payload,
  history,
})
export const setPasswordPending = () => ({
  type: types.SET_PASSWORD_PENDING,
})
export const setPasswordFulfilled = () => ({
  type: types.SET_PASSWORD_FULFILLED,
})
export const setPasswordRejected = (error: any) => ({
  type: types.SET_PASSWORD_REJECTED,
  error,
})
