import type { AxiosResponse } from 'axios'

import type { LoginResponse, Person } from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, post } from '@helpers/xhr'

const { api, baseUrl } = appConfig
const { auth, oauth2 } = api
const { clientId } = auth

export default {
  changePassword(
    id: string,
    data: { oldPassword: string; newPassword: string }
  ) {
    return post(api.people.id.setPassword, data, { entities: { id } })
  },

  faLogin({ code }: { code: string }): Promise<AxiosResponse<LoginResponse>> {
    return get(api.people.faLogin, {
      code,
      redirectUri: `${document.location.origin}${baseUrl}/login`,
    })
  },

  sfLogin({ code }: { code: string }): Promise<AxiosResponse<LoginResponse>> {
    return get(api.people.sfLogin, {
      code,
      redirectUri: `${document.location.origin}${baseUrl}/login/salesforce`,
    })
  },

  logout() {
    return Promise.all([
      get(oauth2.logout, { client_id: clientId }),
      get(api.people.logout),
    ])
  },

  accessTokenCheck(id: string): Promise<AxiosResponse<Person>> {
    return get(api.people.id.default, null, { entities: { id } })
  },
}
