import makeReducer from '@helpers/makeReducer'

import types from './types'

export const INITIAL_STATE: Record<string, any> = {}

export default makeReducer(INITIAL_STATE, {
  [types.UPDATE_MUTATION]: (state, { key, payload }) => ({
    ...state,
    [key]: payload,
  }),
})
