import { IdentityProviderType } from '@b-stock/account-api-client'

import Components from './Components'
import type { RouteDefinitions } from './Router'
import settingsRoutes from './settings/settingsRoutes'

const rootRoutes = {
  businessprofile: {
    path: '/business-profile',
    exact: true,
    component: <Components.BusinessProfileSummaryPage />,
    requireAuth: true,
  },
  buyingEligibility: {
    path: '/buying-eligibility',
    exact: true,
    component: <Components.BusinessProfileBuyingEligibility />,
    requireAuth: true,
  },
  BusinessProfileBackground: {
    path: '/business-background',
    exact: false,
    component: <Components.BusinessProfileBackgroundPage />,
    requireAuth: true,
  },
  BusinessProfileFreightForwarder: {
    path: '/freight-forwarder',
    exact: false,
    component: <Components.FreightForwarderPage />,
    requireAuth: true,
  },
  BusinessProfileTaxIdPage: {
    path: '/business-tax-info',
    exact: false,
    component: <Components.BusinessProfileTaxIdPage />,
    requireAuth: true,
  },
  createprofile: {
    path: '/create-profile',
    exact: false,
    component: <Components.CreateProfilePage />,
    requireAuth: true,
  },
  'special-docs': {
    path: '/special-docs',
    exact: false,
    component: <Components.SpecialDocsPage />,
    requireAuth: true,
  },
  signup: {
    path: '/signup',
    exact: false,
    component: <Components.SignUpPage />,
    requireAuth: false,
  },
  signin: {
    path: '/signin',
    exact: false,
    component: <Components.SignInPage />,
    requireAuth: false,
  },
  login: {
    path: '/login',
    exact: true,
    component: (
      <Components.OAuth2RedirectPage
        identityProvider={IdentityProviderType.Fusionauth}
      />
    ),
    requireAuth: false,
  },
  loginSalesforce: {
    path: '/login/salesforce',
    exact: true,
    component: (
      <Components.OAuth2RedirectPage
        identityProvider={IdentityProviderType.Salesforce}
      />
    ),
    requireAuth: false,
  },
  success: {
    path: '/success',
    exact: false,
    component: <Components.SuccessPage />,
    requireAuth: true,
  },
  transactions: {
    path: '/transactions',
    exact: false,
    component: <Components.Transactions />,
    requireAuth: true,
  },
  home: {
    path: ['/', '/index.html'],
    exact: true,
    component: <Components.HomePage />,
    disabledInEnvs: ['staging', 'production'],
    requireAuth: false,
  },
  settings: {
    router: true,
    path: '/settings',
    routes: settingsRoutes,
  },
} as const satisfies RouteDefinitions

export default rootRoutes
