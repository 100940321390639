import type { Location, History } from 'history'
import type { ParsedQuery } from 'query-string'

import type { IdentityProviderType } from '@b-stock/account-api-client'

import type { AsyncReducerError } from '@helpers/makeReducer'

import type { LoginData } from './reducer'
import types from './types'

export const pending = () => ({
  type: types.AUTH_PENDING,
})

export const fill = (payload: LoginData) => ({
  type: types.AUTH_FULFILLED,
  payload,
})

export const reject = (error: AsyncReducerError) => ({
  type: types.AUTH_REJECTED,
  error,
})

export const loaded = () => ({
  type: types.AUTH_LOADED,
})

export const reset = () => ({
  type: types.AUTH_RESET,
})

export const logout = ({ history }: { history: History }) => ({
  type: types.AUTH_LOGOUT,
  history,
})

export const logoutPending = () => ({
  type: types.AUTH_LOGOUT_PENDING,
})

export const logoutFulfilled = (payload: any) => ({
  type: types.AUTH_LOGOUT_FULFILLED,
  payload,
})

export const logoutRejected = (error: any) => ({
  type: types.AUTH_LOGOUT_REJECTED,
  error,
})

export const clearErrors = () => ({
  type: types.AUTH_CLEAR_ERRORS,
})

export const migrationPending = () => ({
  type: types.AUTH_MIGRATION_PENDING,
})

export const redirect = () => ({
  type: types.AUTH_REDIRECT,
})

export const accessToken = (
  identityProvider: IdentityProviderType,
  payload: ParsedQuery,
  history: History
) => ({
  type: types.ACCESS_TOKEN,
  payload,
  identityProvider,
  history,
})

export const accessTokenPending = () => ({
  type: types.ACCESS_TOKEN_PENDING,
})

export const accessTokenFulfilled = (payload: LoginData) => ({
  type: types.ACCESS_TOKEN_FULFILLED,
  payload,
})

export const accessTokenRejected = (error: any) => ({
  type: types.ACCESS_TOKEN_REJECTED,
  error,
})

export type CheckAccessTokenPayload = {
  location?: Location
  tokenOnly: boolean
  redirectToLogin: boolean
  redirectAfterLogin: string
}

export const checkAccessToken = (
  payload: CheckAccessTokenPayload,
  history: History
) => ({
  type: types.ACCESS_TOKEN_CHECK,
  payload,
  history,
})

export const pendingAccessToken = () => ({
  type: types.ACCESS_TOKEN_CHECK_PENDING,
})

export const accessTokenCheckFulFilled = () => ({
  type: types.ACCESS_TOKEN_CHECK_FULFILLED,
})

export const accessTokenCheckRejected = (error: Error) => ({
  type: types.ACCESS_TOKEN_CHECK_REJECTED,
  error,
})
