/* Our api client often type an argument as `string[]` indicating that that
 * you can pass several params on that key. However, these need to be
 * transmitted as a comma separated list of strings, rather than a multi-params.
 * This file contains some jank to work around that.  */
function isStringArray(input: unknown): input is string[] {
  if (!(input instanceof Array)) {
    return false
  }

  for (let i = 0; i < input.length; i++) {
    if (typeof input[i] !== 'string') {
      return false
    }
  }

  return true
}

// Example:
// { foo: 42, bar: ['baz', 'quxx'] } -> { foo: 42, bar: 'baz,quxx' }
const flattenAPIParams = (input: object) => {
  const output: Record<string, any> = {}
  Object.entries(input).forEach(([key, value]: [string, unknown]) => {
    output[key] = isStringArray(value) ? value.join(',') : value
  })

  return output
}

export { flattenAPIParams }
