import { memo, useMemo } from 'react'

import type { History } from 'history'
import { Helmet } from 'react-helmet-async'
import type { RouteProps } from 'react-router-dom'
import { Route } from 'react-router-dom'

import useAuthCheck from '@helpers/hooks/useauthcheck/useAuthCheck'
import ScrollMemory from '@helpers/ScrollMemory'
import { isBrowser } from '@helpers/ssr'

import { AuthSpinner } from '../privateroute/PrivateRoute'
import { HandlePageConditionalRender } from '../Routes'

const PublicRoute = ({
  path,
  exact = true,
  component,
  render,
  ...props
}: Omit<RouteProps, 'component'> & { component?: React.ReactNode }) => {
  const isLoading = useAuthCheck(false)

  const handleRender = useMemo(
    () =>
      render ||
      (({ history }: { history: History }) => (
        <>
          {isBrowser && (
            <Helmet
              link={[
                {
                  rel: 'canonical',
                  href: window.location.href,
                },
              ]}
            />
          )}

          <ScrollMemory />
          <HandlePageConditionalRender
            history={history}
            component={component}
          />
          {isBrowser && isLoading && <AuthSpinner />}
        </>
      )),
    [render, component, isLoading]
  )

  return <Route exact={exact} path={path} render={handleRender} {...props} />
}

export default memo(PublicRoute)
