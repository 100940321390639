import { Redirect } from 'react-router-dom'

import { interpUri } from '@b-stock/bstock-react'

import useUserInfo from '@helpers/useUserInfo'

import businessProfileRoutes from './businessProfileRoutes'
import Components from '../Components'
import type { RouteDefinitions } from '../Router'

export const OldPersonalSettingsRedirect = () => {
  const { personId } = useUserInfo()
  return <Redirect to={interpUri`/settings/${personId!}/personal`} />
}

const settingsRoutes = {
  settings: {
    path: '/settings',
    exact: true,
    component: <Components.SettingsPage />,
    requireAuth: true,
  },
  personalSettings: {
    path: '/settings/:personId/personal',
    exact: true,
    component: <Components.PersonalSettings />,
    requireAuth: true,
  },
  oldPersonalSettings: {
    path: '/settings/personal',
    exact: true,
    component: <OldPersonalSettingsRedirect />,
    requireAuth: true,
  },
  'payment-method': {
    path: '/settings/:accountId/payment-method',
    exact: true,
    component: <Components.PaymentMethod />,
    requireAuth: true,
  },
  'listing-settings': {
    path: '/settings/listing',
    exact: true,
    component: <Components.ListingSettingsPage />,
    requireAuth: true,
  },
  'subscription-management': {
    path: '/settings/subscription-management',
    exact: true,
    component: <Components.SubscriptionManagementPage />,
    requireAuth: true,
  },
  'notification-preferences': {
    path: '/settings/:personId/notification-preferences',
    exact: true,
    component: <Components.NotificationPreferencesPage />,
    requireAuth: true,
  },
  'sms-preference': {
    path: '/settings/:personId/notification-preferences/sms-preferences',
    exact: true,
    component: <Components.SmsNotificationPage />,
    requireAuth: true,
  },
  'email-preference': {
    path: '/settings/:personId/notification-preferences/email-preferences',
    exact: true,
    component: <Components.EmailNotificationPage />,
    requireAuth: true,
  },
  'business-profile': {
    router: true,
    path: '/settings/business-profile',
    routes: businessProfileRoutes,
  },
  'marketplace-restrictions': {
    path: '/settings/:accountId/marketplace-eligibility/:siteAbb',
    exact: true,
    component: <Components.MarketplaceRestrictionPage />,
    requireAuth: true,
  },
  'marketplace-eligibility': {
    path: '/settings/:accountId/marketplace-eligibility',
    exact: true,
    component: <Components.MarketplaceEligibilityPage />,
    requireAuth: true,
  },
} as const satisfies RouteDefinitions

export default settingsRoutes
