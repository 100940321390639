import type { AxiosResponse } from 'axios'

import type {
  CheckExistingResponse as CheckExistingResponse,
  CreatePersonDto,
  VerifyEmailCodeResponse,
  Person,
} from '@b-stock/account-api-client'
import { IdentityProviderType } from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { sanitizeEmail } from '@helpers/sanitize'
import { get, post } from '@helpers/xhr'

const { api } = appConfig

type EntBuyerRegistration = {
  siteAbb: string
  status: string
}

// As of this writing, `CheckExistingResponse` marks every field as optional,
// we want to strengthen the type promise by ensuring some of the booleans
// can't be undefined (we convert an undefined value to false)
type InternalCheckExistingResponse = CheckExistingResponse & {
  hasCredentials: boolean
  isEmailVerified: boolean
  exists: boolean
  identityProviderType: IdentityProviderType
}

export default {
  async checkUserExists({
    email,
  }: {
    email: string
  }): Promise<InternalCheckExistingResponse> {
    const { data } = await get<any>(api.people.checkExisting, {
      email: sanitizeEmail(email),
    })

    // Set defaults for optional fields. BE returns a (mostly) empty object
    // if the user does't exist.
    return {
      identityProviderType: IdentityProviderType.Fusionauth,
      hasCredentials: false,
      isEmailVerified: false,
      exists: !!data._id,
      ...data,
    }
  },

  async checkEnterpriseAccountExists({ email }: { email: string }) {
    const response = await get(api.bridge.uaExists, {
      email: sanitizeEmail(email),
    })
    return response
  },

  async checkMageExists({ email }: { email: string }) {
    const { data } = await get<EntBuyerRegistration[]>(api.bridge.mageExists, {
      email: sanitizeEmail(email),
    })
    return data
  },

  async migrateMagentoUser() {
    const response = await post(api.bridge.accountsMigrate, null as any)
    return response
  },

  async checkBridExists(queryParams: { brid: string; country: string }) {
    if (!queryParams.brid) {
      return false
    }

    const { data } = await get<any>(api.accounts.default, queryParams)
    return !!data?.accounts?.length
  },

  async submitEmail(data: CreatePersonDto): Promise<AxiosResponse<Person>> {
    const sanitizedData = { ...data, email: sanitizeEmail(data.email) }
    return post(`${api.people.default}?termsAccepted=true`, sanitizedData)
  },

  async fetchVerificationCode({
    id,
  }: {
    id: string
  }): Promise<AxiosResponse<void>> {
    return post(api.people.id.resendValidationEmail, {}, { entities: { id } })
  },

  async verifyCode({
    peopleId,
    verificationCode,
  }: {
    peopleId: string
    verificationCode: string
  }) {
    const response = await get<VerifyEmailCodeResponse>(
      api.people.id.verifyEmailCode,
      { verificationCode },
      { entities: { id: peopleId } }
    )
    return response
  },

  setPassword({
    peopleId,
    verificationCode,
    oldPassword,
    newPassword,
  }: {
    peopleId: string
    verificationCode: string
    oldPassword: string
    newPassword: string
  }) {
    return post(
      api.people.id.setPassword,
      {
        ...(verificationCode === '' || verificationCode === undefined
          ? {}
          : { verificationCode }),
        ...(oldPassword === '' || oldPassword === undefined
          ? {}
          : { oldPassword }),
        newPassword,
      },
      { entities: { id: peopleId } }
    )
  },
}
