import memoize from 'lodash-es/memoize'
import * as Yup from 'yup'

import api from '@api/location'

const getCountryData = memoize(
  async (country: string) => await api.fetchNationalExemptionReqs(country)
)

Yup.addMethod(
  Yup.string,
  'postalCode',
  function (country?: string, optional?: boolean) {
    const valueTest = async (value: string | undefined, ctx: any) => {
      let message = ''

      const countryData = country
        ? await getCountryData(country)
        : {
            postcode: {
              regex: '',
            },
          }
      const regex = new RegExp(countryData?.postcode?.regex || '')
      if (!value) {
        if (optional || !countryData?.postcode?.regex) {
          return true
        }
        message = 'zip.required'
      } else if (value.length < 3) {
        message = 'zip.tooShort'
      } else if (value.length > 12) {
        message = 'zip.tooLong'
      } else if (country && !regex.test(value)) {
        message = 'zip.invalid'
      }

      if (message) {
        return ctx.createError({ message })
      }
      return true
    }

    return this.ensure().trim().test({
      test: valueTest,
      message: 'zip',
    })
  }
)

declare module 'yup' {
  interface StringSchema {
    postalCode(country?: string, optional?: boolean): Yup.StringSchema
  }
}

export const postalCodeWhenCountryCode = (
  countryCodeKey: string,
  optional?: boolean
) =>
  Yup.string().when(countryCodeKey, ([countryCode]) =>
    Yup.string().postalCode(countryCode, optional)
  )
