import { loadableReady } from '@loadable/component'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import appConfig from '@config/config'
import CookieManager from '@helpers/CookieManager'
import presetStore from '@store/store'

import App from './App'
import { determineUserLang } from '../../locale'

const { baseUrl } = appConfig

const rootElement = document.getElementById('app')
const root = createRoot(rootElement)

const language = determineUserLang(
  CookieManager.getCookie('language') || navigator.languages || []
)

loadableReady(() =>
  root.render(
    <HelmetProvider>
      <Provider store={presetStore}>
        <BrowserRouter basename={baseUrl}>
          <App language={language} />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  )
)
