type JSONPrimitive = string | number | boolean | null
type JSONValue = JSONPrimitive | JSONObject | JSONArray
type JSONArray = JSONValue[]
type JSONObject = { [member: string]: JSONValue }

declare global {
  interface Window {
    dataLayer: JSONArray
  }
}
export const gtag = (...args: JSONArray) => {
  window.dataLayer = window.dataLayer ? window.dataLayer : []
  window.dataLayer.push(...args)
}

export const gtagFields = Object.freeze({
  event: {
    login: 'login',
    signup_request: 'signup_request',
    signup_confirmation: 'signup_confirmation',
  },
  website_flow: '3MP',
  method: {
    fusionAuth: 'fusionauth',
  },
  user_status: {
    logged_in: 'Logged in',
    logged_out: 'Guest',
  },
})
