import { all } from 'redux-saga/effects'

import changePasswordSaga from './changePassword/saga'
import loginSaga from './login/saga'
import signinSaga from './signIn/saga'
import signupSaga from './signUp/saga'

/**
 * Redux Saga watchers
 * @yield {Function} Effect description
 */

export default function* rootSaga() {
  try {
    yield all([loginSaga(), signinSaga(), signupSaga(), changePasswordSaga()])
  } catch (err) /* istanbul ignore next */ {
    console.error(err)
  }
}
