// TODO: Eventually, we should replace the entire redux store creation with something more modern (e.g. configureStore in RTK, as recommended by Redux)
/* eslint-disable @typescript-eslint/no-deprecated */
import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export const buildStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]
  let composeEnhancers = compose

  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'test') {
    // const logger = require('redux-logger')

    // middlewares.push(logger.createLogger())
    composeEnhancers =
      (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose
  }

  const storeWithMiddlewares = composeEnhancers<typeof createStore>(
    applyMiddleware(...middlewares)
  )(createStore)

  const store = storeWithMiddlewares(rootReducer)

  sagaMiddleware.run(rootSaga)

  return store
}

export default buildStore()
