import { useAnalytics as useAnalyticsShared } from '@b-stock/bstock-react'

import config from '@config/config'
import useUserInfo from '@helpers/useUserInfo'

// comment out config and key to test

// backwards compat for existing unit test mocking
export { setAnalytics } from '@b-stock/bstock-react'

const useAnalytics = () => {
  const { accountId, personId } = useUserInfo()

  return useAnalyticsShared({
    accountId,
    personId,
    segmentKey: config.segment.wKey,
  })
}

export default useAnalytics
