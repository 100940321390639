import makeReducer from '@helpers/makeReducer'

import types from '../types'

export const INITIAL_STATE = {
  signUpEmail: '',
}

export default makeReducer(INITIAL_STATE, {
  [types.SET_SIGN_UP_EMAIL]: (state, { signUpEmail }) => ({
    ...state,
    signUpEmail,
  }),
})
