import type * as React from 'react'

import { Route as RRDRoute, Switch } from 'react-router-dom'

import config from '@config/config'

import Components from './Components'
import PublicRoute from './publicroute/PublicRoute'
import Route from './Route'

type PathDefinition = {
  path: string | string[]
  disabledInEnvs?: string[]
}
export type RouterDefinition = PathDefinition & {
  router: true
  routes: RouteDefinitions
}
export type RouteDefinition = PathDefinition & {
  router?: false
  exact: boolean
  requireAuth: boolean
} & (
    | {
        component: React.ReactNode
        redirectTo?: undefined
      }
    | {
        component?: undefined
        redirectTo: string
      }
  )

export type RouteDefinitions = {
  [key: string]: RouteDefinition | RouterDefinition
}
type RouterProps = {
  routes: RouteDefinitions
}

const Router = ({ routes }: RouterProps) => (
  <Switch>
    {Object.entries(routes)
      .filter(([, route]) => !route.disabledInEnvs?.includes(config.env))
      .map(([key, route]) => {
        const path = route.path

        if (route.router) {
          return (
            <RRDRoute key={key} path={path}>
              <Router routes={route.routes} />
            </RRDRoute>
          )
        }

        return <Route key={key} {...route} />
      })}
    <PublicRoute
      path="*"
      exact={false}
      component={<Components.NoMatchPage />}
      render={undefined}
    />
  </Switch>
)

export default Router
