import type { AsyncReducerState } from '@helpers/makeReducer'
import makeReducer, {
  asyncReducer,
  INITIAL_ASYNC_REDUCER_STATE,
} from '@helpers/makeReducer'

import types from '../types'

export type CheckEmailFulfilledPayload = {
  _id?: string
  email: string
  isEmailVerified?: boolean
  hasCredentials?: boolean
  exists?: boolean
}
type CheckEmailState = AsyncReducerState & Partial<CheckEmailFulfilledPayload>
const INITIAL_CHECK_EMAIL_STATE: CheckEmailState = {
  ...INITIAL_ASYNC_REDUCER_STATE,
}

export default makeReducer<CheckEmailState>(
  INITIAL_CHECK_EMAIL_STATE,
  asyncReducer<CheckEmailState>({
    types: {
      pending: types.CHECK_EMAIL_PENDING,
      fulfilled: types.CHECK_EMAIL_FULFILLED,
      rejected: types.CHECK_EMAIL_REJECTED,
    },
    parsePayload: ({
      _id,
      email,
      isEmailVerified,
      hasCredentials,
      exists,
    }: CheckEmailFulfilledPayload) => ({
      _id,
      email,
      isEmailVerified,
      hasCredentials,
      exists,
    }),
  })
)
