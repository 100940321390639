const getConfig = () => {
  const publicPath = process.env.APP_BASE_PATH
  const baseUrl = publicPath

  return {
    publicPath,
    baseUrl,
  }
}

const config = getConfig()

export default config
