import type {
  UpdateAccountDto as UpdateAccountParams,
  OnboardingRequirements1,
  Account,
} from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, patch } from '@helpers/xhr'

const { api } = appConfig

type SaveAccountArg = {
  data: { id: string } & UpdateAccountParams
}

export default {
  async fetchAccount({ id }: { id: string }) {
    const { data } = await get<Account>(api.accounts.id.default, null, {
      entities: { id },
    })

    return data
  },

  async saveAccount({ data: { id, ...data } }: SaveAccountArg) {
    const { data: response } = await patch(api.accounts.id.default, data, {
      entities: { id },
    })
    return response
  },

  async fetchPerson({ id }: { id: string }) {
    const { data } = await get(api.people.id.default, null, {
      entities: { id },
    })
    return data
  },

  async getAskmeToken() {
    const { data } = await get(api.askMe.default)
    return data
  },

  async getSiteOnboardingRequirementsStatus({
    id,
    siteAbb,
  }: {
    id: string
    siteAbb: string
  }) {
    const { data } = await get<OnboardingRequirements1[]>(
      api.accounts.id.requirements.siteAbb,
      null,
      {
        entities: { id, siteAbb },
      }
    )
    return data
  },
}
