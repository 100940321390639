import { combineReducers } from 'redux'

import auth from './auth/reducer'
import mutations from './mutations/reducer'
import ui from './ui/reducer'

const rootReducer = combineReducers({
  auth,
  ui,
  mutations,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
