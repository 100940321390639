import { useMemo } from 'react'

import type { RouteComponentProps } from 'react-router-dom'
import { Redirect, generatePath } from 'react-router-dom'

import PrivateRoute from './privateroute/PrivateRoute'
import PublicRoute from './publicroute/PublicRoute'
import type { RouteDefinition } from './Router'

const Route = ({
  path,
  exact,
  component,
  requireAuth,
  redirectTo,
}: RouteDefinition) => {
  const RouteComponent = requireAuth ? PrivateRoute : PublicRoute

  const render = useMemo(
    () =>
      redirectTo
        ? ({ match: { params } }: RouteComponentProps<any>) => (
            <Redirect to={generatePath(redirectTo, params)} />
          )
        : undefined,
    [redirectTo]
  )

  return (
    <RouteComponent
      exact={exact}
      path={path}
      component={component}
      render={render}
    />
  )
}

export default Route
