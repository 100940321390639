import * as Yup from 'yup'

import { isPhoneValue, isValidPhoneValue } from '@helpers/phoneUtils'

Yup.addMethod(Yup.array, 'phone', function () {
  return this.test({ test: isValidPhoneValue, message: 'phone' })
})

Yup.addMethod(Yup.array, 'phoneRequired', function () {
  const message = 'required'

  return this.min(2, message).test({
    test: (value?: unknown[]) => {
      if (!value || !isPhoneValue(value)) {
        return false
      }

      const [country, natNum] = value || []
      return !!(country?.abbr && country?.code && natNum)
    },
    message,
  })
})

declare module 'yup' {
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
    T = unknown,
    C = unknown,
    TOut = unknown,
  > {
    phone(): Yup.ArraySchema<TIn, TContext, TDefault, TFlags, T, C, TOut>
    phoneRequired(): Yup.ArraySchema<
      TIn,
      TContext,
      TDefault,
      TFlags,
      T,
      C,
      TOut
    >
  }
}
