import axios from 'axios'
import jwtDecode from 'jwt-decode'

import type { RefreshTokenResponse } from '@b-stock/account-api-client'
import { ddLogger } from '@b-stock/bstock-react'

import appConfig from '@config/config'
import CookieManager from '@helpers/CookieManager'

type TokenPair = {
  refreshToken: string
  accessToken: string
}

const getUpdatedTokens = async (
  accessToken: string,
  refreshToken: string
): Promise<TokenPair> => {
  // Use axios directly instead of going through the XHR helpers here because
  // the helpers potentially call this function as part of normal operation
  const tokenContent = jwtDecode<JWTAccessToken>(accessToken)

  let newAccessToken: string
  let newRefreshToken: string

  // This is a salesforce access token, we use a different endpoint
  if (tokenContent.sfRefreshToken) {
    const { data, status } = await axios.post<RefreshTokenResponse>(
      appConfig.api.salesforce.refresh,
      { refreshToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (status !== 200) {
      throw new Error(`Error refreshing token, got status code ${status}.`)
    }
    newAccessToken = data.access_token
    newRefreshToken = data.refresh_token ?? refreshToken
  } else {
    const { data, status } = await axios.post(
      appConfig.api.oauth2.refresh,
      { refreshToken },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (status !== 200) {
      throw new Error(`Error refreshing token, got status code ${status}.`)
    }
    newAccessToken = data.token
    newRefreshToken = data.refreshToken
  }

  return {
    refreshToken: newRefreshToken,
    accessToken: newAccessToken,
  }
}

const pendingRefreshes: Partial<Record<string, Promise<TokenPair>>> = {}

const refreshAccessToken = async (
  accessToken: string,
  refreshToken: string
) => {
  let pendingRefresh = pendingRefreshes[refreshToken]
  if (pendingRefresh) {
    // In a scenario where an access token is expired and several processes
    // need to make API calls (e.g. loading a new page) we can get several
    // concurrent calls to `refreshAccessToken`. This structure ensures only
    // the first initiates network IO, all others will await the same call.
    return pendingRefresh
  }
  pendingRefresh = getUpdatedTokens(accessToken, refreshToken)
  pendingRefreshes[refreshToken] = pendingRefresh

  try {
    const updatedTokens = await pendingRefresh

    CookieManager.setCookie('bstock_access_token', updatedTokens.accessToken)
    CookieManager.setCookie('bstock_refresh_token', updatedTokens.refreshToken)

    return updatedTokens
  } finally {
    pendingRefreshes[refreshToken] = undefined
  }
}

export enum RoleStatus {
  UNUSED = 'UNUSED',
  INTENDED = 'INTENDED',
  ACTIVE = 'ACTIVE',
  READ_ONLY = 'READ_ONLY',
  SUSPENDED = 'SUSPENDED',
}

export type AccountRoles = {
  buyer?: RoleStatus
  seller?: RoleStatus
  carrier?: RoleStatus
  service?: RoleStatus
}

type Marketplace = {
  siteAbb: string
  isComplete: string
}

export type JWTAccessToken = {
  ua_id: string
  exp: number
  accountId: string
  personId: string
  email: string
  accountRoles: {
    [index: string]: AccountRoles
  }
  tokenType: string
  sites: { [key: string]: Marketplace }
  sfAccessToken?: string
  sfRefreshToken?: string
}

const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const accessToken = CookieManager.getCookie('bstock_access_token')

    if (!accessToken) {
      return undefined
    }

    const tokenContent = jwtDecode<JWTAccessToken>(accessToken)

    if (tokenContent.exp > ~~(Date.now() / 1000) + 30) {
      // Access token is present and unexpired, good to go
      return accessToken
    }
    // Access token needs to be refreshed if it can be
    const refreshToken = CookieManager.getCookie('bstock_refresh_token')
    if (!refreshToken) {
      ddLogger.debug(
        'Access token expired, No refresh token available, deleting access token cookie.'
      )
      CookieManager.deleteCookie('bstock_access_token')
      return
    }

    const updatedTokens = await refreshAccessToken(accessToken, refreshToken)

    return updatedTokens.accessToken
  } catch {
    ddLogger.debug(
      'Error refreshing access token, deleting access and refresh token cookies.'
    )
    CookieManager.deleteCookie('bstock_access_token')
    CookieManager.deleteCookie('bstock_refresh_token')
    return
  }
}

const getDecodedAccessTokenWithoutRefresh = () => {
  const accessToken = CookieManager.getCookie('bstock_access_token')
  if (!accessToken) {
    throw new Error('Access token was not set')
  }
  return jwtDecode<JWTAccessToken>(accessToken)
}

export {
  refreshAccessToken,
  getAccessToken,
  getDecodedAccessTokenWithoutRefresh,
}
