import jwtEncode from 'jwt-encode'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class CookieManager {
  static checkCookie = (name: string) =>
    document.cookie
      .split(';')
      .some((item) => item.trim().startsWith(`${name}=`))

  static getCookie = (name: string) => {
    const doesCookieExist = this.checkCookie(name)

    if (doesCookieExist) {
      const cookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith(name))
      return cookie && cookie.split('=')[1]
    }
    return null
  }

  static setCookie = (name: string, value: string | null | undefined) => {
    if (this.checkCookie(name)) {
      this.deleteCookie(name)
    }

    const mergedOptions: Record<string, string | number | boolean> = {
      path: '/',
    }

    if (document.location.protocol === 'https:') {
      mergedOptions.secure = true
    }

    const cookieParts = [`${name}=${value}`]

    for (const optionKey in mergedOptions) {
      const optionValue = mergedOptions[optionKey]
      if (optionValue === true) {
        cookieParts.push(optionKey)
      } else {
        cookieParts.push(`${optionKey}=${optionValue}`)
      }
    }

    return (document.cookie = cookieParts.join(';'))
  }

  static deleteCookie = (key: string) => {
    document.cookie = `${key}=; expires=${new Date().toUTCString()}`
  }

  static deleteAllCookies = () =>
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=; expires=${new Date().toUTCString()};path=/`)
    })

  static setMockedToken = (accountId: string, personId: string) =>
    CookieManager.setCookie(
      'bstock_access_token',
      jwtEncode(
        {
          accountRoles: {
            [accountId]: {
              buyer: 'ACTIVE',
              seller: 'READ_ONLY',
            },
          },
          accountId,
          personId,
        },
        ''
      )
    )
}

// TODO: remove it when we have the login process working
/*
 * Usage:
 * CookieManager.setCookie('bstock_access_token', [encodedTokenString])
 * Decoded json example (from: http://account.bstock-dev.com:3302/v1/api-docs):
{
 "accountRoles": {
   "ObjectId": {
     "buyer": "ACTIVE",
     "seller": "READ_ONLY"
   }
 },
 "accountId": "ObjectId",
 "personId": "ObjectId"
}
* */
declare global {
  // Needs to be var for typescript to know this exists on globalThis
  // eslint-disable-next-line no-var
  var CookieManager: CookieManager
}
globalThis.CookieManager = CookieManager

export default CookieManager
