import { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { checkAccessToken } from '@store/auth/login/actions'
import type { RootState } from '@store/rootReducer'

const useAuthCheck = (requireAuth = true) => {
  const { isLoggedIn } = useSelector(({ auth }: RootState) => auth.login)
  const { initLoadComplete } = useSelector(({ ui }: RootState) => ui)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const params = location.search ?? ''

    if (
      requireAuth &&
      (!location.state || (location.state && !location.state.noAuthCheck)) &&
      !isLoggedIn
    ) {
      dispatch(
        checkAccessToken(
          {
            tokenOnly: isLoggedIn,
            redirectToLogin: requireAuth,
            redirectAfterLogin: `${location.pathname}${params}`,
          },
          history
        )
      )
    } else if (location.state && location.state.noAuthCheck) {
      history.replace(`${location.pathname}${params}`, {
        noAuthCheck: false,
      })
    } else if (!initLoadComplete) {
      dispatch(
        checkAccessToken(
          {
            location,
            tokenOnly: isLoggedIn,
            redirectToLogin: requireAuth,
            redirectAfterLogin: `${location.pathname}${params}`,
          },
          history
        )
      )
    }

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loading = !initLoadComplete || (!isLoggedIn && requireAuth)

  return loading
}

export default useAuthCheck
