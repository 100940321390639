import types from './types'

export const changePassword = (payload) => ({
  type: types.CHANGE_PASSWORD,
  payload,
})

export const pending = () => ({
  type: types.CHANGE_PASSWORD_PENDING,
})

export const fill = (payload) => ({
  type: types.CHANGE_PASSWORD_FULLFILLED,
  payload,
})

export const reject = (error) => ({
  type: types.CHANGE_PASSWORD_REJECTED,
  error,
})

export const reset = () => ({
  type: types.CHANGE_PASSWORD_RESET,
})
