import type { ReactNode } from 'react'
import { useMemo } from 'react'

import type { LDContext, LDOptions } from 'launchdarkly-react-client-sdk'
import { LDProvider } from 'launchdarkly-react-client-sdk'

const loggerOptions: LDOptions = {
  logger: {
    debug(message) {
      console.debug('[LaunchDarkly]', message)
    },
    info(message) {
      console.info('[LaunchDarkly]', message)
    },
    warn(message) {
      console.warn('[LaunchDarkly]', message)
    },
    error(message) {
      console.error('[LaunchDarkly]', message)
    },
  },
}

const reactOptions = {
  useCamelCaseFlagKeys: false,
}

export const FeatureFlagProvider = ({
  children,
  accountId,
  clientSideId,
}: {
  children: ReactNode
  accountId: string | null
  clientSideId: string
}) => {
  const context = useMemo<LDContext>(
    () => ({
      kind: 'user',
      key: accountId ?? 'anonymous-user',
      anonymous: !accountId,
    }),
    [accountId]
  )

  return (
    <LDProvider
      clientSideID={clientSideId}
      context={context}
      reactOptions={reactOptions}
      options={loggerOptions}
    >
      {children}
    </LDProvider>
  )
}
