import head from 'lodash-es/head'
import * as Yup from 'yup'

// Default max file size is a 10MB
Yup.addMethod(
  Yup.mixed,
  'fileSize',
  function (maxSize = 10, message = 'fileSize') {
    const maxSizeInMB = maxSize * 1024 * 1024

    return this.test(message, message, (files) => {
      const file = head(Array.isArray(files) ? files : [])

      return (file?.size || 0) <= maxSizeInMB
    })
  }
)

declare module 'yup' {
  interface MixedSchema<
    // This needs to match Yup's definition
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    TType = {} | undefined,
    TContext = Yup.AnyObject,
    TDefault = undefined,
    TFlags = '',
    TOut = unknown,
  > {
    fileSize(): Yup.MixedSchema<TType, TContext, TDefault, TFlags, TOut>
  }
}
