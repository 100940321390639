import { all } from 'redux-saga/effects'

import authSaga from './auth/saga'
import uiSaga from './ui/saga'

/**
 * Redux Saga watchers
 * @yield {Function} Effect description
 */

export default function* rootSaga() {
  try {
    yield all([authSaga(), uiSaga()])
  } catch (err) /* istanbul ignore next */ {
    console.error(err)
  }
}
