import type { AsyncReducerState } from '@helpers/makeReducer'
import makeReducer, {
  asyncReducer,
  INITIAL_ASYNC_REDUCER_STATE,
} from '@helpers/makeReducer'

import types from '../types'

export type SubmitEmailFulfilledPayload = {
  _id: string
  email: string
  isEmailVerified: boolean
  accountId?: string
  isActive?: boolean
  hasCredentials?: boolean
  exists?: boolean
}
type SubmitEmailState = AsyncReducerState & Partial<SubmitEmailFulfilledPayload>

const INITIAL_SUBMIT_EMAIL_STATE: SubmitEmailState = {
  ...INITIAL_ASYNC_REDUCER_STATE,
}

export default makeReducer(
  INITIAL_SUBMIT_EMAIL_STATE,
  asyncReducer<SubmitEmailState>({
    types: {
      pending: types.SUBMIT_EMAIL_PENDING,
      fulfilled: types.SUBMIT_EMAIL_FULFILLED,
      rejected: types.SUBMIT_EMAIL_REJECTED,
    },
    parsePayload: ({
      _id,
      accountId,
      isActive,
      email,
      isEmailVerified,
      hasCredentials,
      exists,
    }: SubmitEmailFulfilledPayload) => ({
      _id,
      accountId,
      isActive,
      email,
      isEmailVerified,
      hasCredentials,
      exists,
    }),
    parseReset: () => INITIAL_SUBMIT_EMAIL_STATE,
  })
)
