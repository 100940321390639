import appConfig from '@config/config'
import { get, patch } from '@helpers/xhr'
import type { FreightForwardState } from '@pages/businessProfile/freightForwarder/workflow'

const url = appConfig.api.accounts.id.roles.accountsIdRole.onboarding

export type PatchData = {
  [key: string]: {
    completion?: number
    wasSkipped?: boolean
    additionalProperties: {
      [key: string]: {
        historyStack: string[] | never[]
        currentStep: string
        state: FreightForwardState
      }
    }
  }
}

export default {
  load: async ({ id, role }: { id: string; role: string }) => {
    const { data } = await get<any>(url, null, {
      entities: { id, role },
    })
    return data
  },

  save: ({ id, role, data }: { id: string; role: string; data: PatchData }) =>
    patch(url, data, {
      entities: { id, role },
    }),
}
