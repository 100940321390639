export default Object.freeze({
  CHECK_EMAIL: 'CHECK_EMAIL',
  CHECK_EMAIL_PENDING: 'CHECK_EMAIL_PENDING',
  CHECK_EMAIL_FULFILLED: 'CHECK_EMAIL_FULFILLED',
  CHECK_EMAIL_REJECTED: 'CHECK_EMAIL_REJECTED',

  SUBMIT_EMAIL: 'SUBMIT_EMAIL',
  SUBMIT_EMAIL_PENDING: 'SUBMIT_EMAIL_PENDING',
  SUBMIT_EMAIL_FULFILLED: 'SUBMIT_EMAIL_FULFILLED',
  SUBMIT_EMAIL_REJECTED: 'SUBMIT_EMAIL_REJECTED',

  SUBMIT_VERIFICATION_CODE: 'SUBMIT_VERIFICATION_CODE',
  SUBMIT_VERIFICATION_CODE_PENDING: 'SUBMIT_VERIFICATION_CODE_PENDING',
  SUBMIT_VERIFICATION_CODE_FULFILLED: 'SUBMIT_VERIFICATION_CODE_FULFILLED',
  SUBMIT_VERIFICATION_CODE_REJECTED: 'SUBMIT_VERIFICATION_CODE_REJECTED',

  FETCH_VERIFICATION_CODE: 'FETCH_VERIFICATION_CODE',
  FETCH_VERIFICATION_CODE_PENDING: 'FETCH_VERIFICATION_CODE_PENDING',
  FETCH_VERIFICATION_CODE_FULFILLED: 'FETCH_VERIFICATION_CODE_FULFILLED',
  FETCH_VERIFICATION_CODE_REJECTED: 'FETCH_VERIFICATION_CODE_REJECTED',

  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_PENDING: 'SET_PASSWORD_PENDING',
  SET_PASSWORD_FULFILLED: 'SET_PASSWORD_FULFILLED',
  SET_PASSWORD_REJECTED: 'SET_PASSWORD_REJECTED',

  SET_SIGN_UP_EMAIL: 'SET_SIGN_UP_EMAIL',
})
