import addressBookRoutes from './addressBookRoutes'
import Components from '../Components'
import type { RouteDefinitions } from '../Router'

const businessProfileRoutes = {
  businessprofilesettings: {
    path: '/settings/business-profile',
    exact: true,
    component: <Components.BusinessProfileSettingsPage />,
    requireAuth: true,
  },
  businessprofilebuyingeligibility: {
    path: '/settings/business-profile/:accountId/buying-eligibility',
    exact: true,
    component: <Components.BusinessProfileBuyingEligibility />,
    requireAuth: true,
  },
  createBusinessProfile: {
    path: '/settings/business-profile/:accountId/:personId/business-info',
    exact: true,
    component: <Components.CreateBusinessProfile />,
    requireAuth: true,
  },
  'address-book': {
    router: true,
    path: '/settings/business-profile/:accountId/address-book',
    routes: addressBookRoutes,
  },

  // Old paths
  businessprofilebusinesseligibility: {
    path: '/settings/business-profile/:accountId/business-eligibility',
    exact: true,
    requireAuth: true,
    redirectTo: '/settings/business-profile/:accountId/buying-eligibility',
  },
} as const satisfies RouteDefinitions

export default businessProfileRoutes
