import { useEffect } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Helmet } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'

import {
  ddLogger,
  ErrorBoundary,
  SnackbarContainer,
} from '@b-stock/bstock-react'

import appConfig from '@config/config'
import { FeatureFlagProvider } from '@helpers/featureFlags/featureFlags'
import './initializeFA'
import '@helpers/validation'
import { gtag, gtagFields } from '@helpers/gtag'
import { useLogError } from '@helpers/logError'
import useUserInfo from '@helpers/useUserInfo'
import useAnalytics from '@pages/shared/segmentAnalytics/useAnalytics'
import { queryClient } from '@store/data'
import type { RootState } from '@store/rootReducer'

import GlobalStyle from './GlobalStyle'
import Routes from './routes/Routes'
import localeData from '../../locale/localeData'

import '@b-stock/bstock-react/styles/global.css'
import 'react-toastify/dist/ReactToastify.min.css'

ddLogger.startLogger({
  datadogConfig: appConfig.datadog,
  level: appConfig.datadog.level,
  env: appConfig.env,
  enabled: appConfig.datadog.enabled,
  appName: appConfig.appName,
})

const isDev = process.env.NODE_ENV === 'development'

const App = ({ language }: { language: keyof typeof localeData }) => {
  const { identifyUser } = useAnalytics()
  const { accountId, personId } = useUserInfo()
  const { TITLE } = appConfig.site
  const { pathname, search } = useLocation()
  const { isLoggedIn } = useSelector(({ auth }: RootState) => auth.login)

  useEffect(() => {
    if (accountId && personId) {
      identifyUser(personId, accountId)
      ddLogger.setGlobalContext({ token: { accountId, personId } })
    }
    gtag({
      content_group: 'account',
      user_status: isLoggedIn
        ? gtagFields.user_status.logged_in
        : gtagFields.user_status.logged_out,
      user_id: accountId || 'undefined',
      page_type: `${pathname}${search ? `${search}` : ''}`,
    })
  }, [accountId, identifyUser, isLoggedIn, pathname, personId, search])

  const logError = useLogError()

  return (
    <FeatureFlagProvider
      accountId={accountId || null}
      clientSideId={appConfig.launchDarklyClientSideId}
    >
      <StyleSheetManager enableVendorPrefixes>
        <QueryClientProvider client={queryClient}>
          {isDev ? <ReactQueryDevtools /> : null}

          <IntlProvider locale={language} messages={localeData[language]}>
            <ErrorBoundary onError={logError}>
              <Helmet>
                <title>{TITLE}</title>
              </Helmet>
              <GlobalStyle />
              <Routes />
              <SnackbarContainer />
            </ErrorBoundary>
          </IntlProvider>
        </QueryClientProvider>
      </StyleSheetManager>
    </FeatureFlagProvider>
  )
}

export default App
