import find from 'lodash-es/find'
import isUndefined from 'lodash-es/isUndefined'
import * as Yup from 'yup'

export const checkPasswordWeakness = (value = '') => {
  const errors = {
    length: value?.length > 7,
    lowercase: /.*[a-z]+.*/.test(value),
    uppercase: /.*[A-Z]+.*/.test(value),
    numeric: /.*\d+.*/.test(value),
    special: /.*[\s!@#$%^&*].*/.test(value),
  }
  const isWeak = !isUndefined(find(errors, (e) => !e))

  return { isWeak, errors }
}

Yup.addMethod(Yup.string, 'password', function () {
  return this.test({
    test: (value) => (value ? !checkPasswordWeakness(value).isWeak : true),
    message: 'password.weak',
  })
})

declare module 'yup' {
  interface StringSchema {
    password(): Yup.StringSchema
  }
}
