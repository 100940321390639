import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'signature', function () {
  return this.matches(
    /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
    {
      message: 'signature',
    }
  )
})
