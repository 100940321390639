import makeReducer, { asyncReducer } from '@helpers/makeReducer'

import types from './types'

export const INITIAL_STATE = {
  loading: false,
  error: null,
  email: '',
}

export default makeReducer(INITIAL_STATE, {
  ...asyncReducer({
    types: {
      pending: types.FETCH_PERSON_PENDING,
      fulfilled: types.FETCH_PERSON_FULFILLED,
      rejected: types.FETCH_PERSON_REJECTED,
    },
  }),
  ...asyncReducer({
    types: {
      pending: types.SIGN_IN_SUBMIT_EMAIL_PENDING,
      fulfilled: types.SIGN_IN_SUBMIT_EMAIL_FULFILLED,
      rejected: types.SIGN_IN_SUBMIT_EMAIL_REJECTED,
    },
  }),
  [types.SIGN_IN_SET_STEP_PENDING]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [types.SIGN_IN_SET_STEP_FULFILLED]: (state, { step }) => ({
    ...state,
    step,
    loading: false,
    error: null,
  }),
  [types.SIGN_IN_SET_STEP_REJECTED]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [types.SIGN_IN_SUBMIT_PASSWORD_PENDING]: (state) => ({
    ...state,
    loading: true,
  }),
  [types.SIGN_IN_SUBMIT_PASSWORD_FULFILLED]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
  [types.SIGN_IN_SUBMIT_PASSWORD_REJECTED]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
})
