import { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import usePrevious from './hooks/useprevious/usePrevious'

function useScrollMemory() {
  const history = useHistory()
  const prevPath = usePrevious(history.location.pathname)
  useEffect(() => {
    if (history.action !== 'POP' && history.location.pathname !== prevPath) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.action, history.location.pathname])
}

function ScrollMemory() {
  useScrollMemory()
  return null
}

export default ScrollMemory
