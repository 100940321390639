import { mergeLocaleData } from '@b-stock/bstock-react/locale'
import { payUiEnLocaleData } from '@b-stock/pay-ui'

import enData from './translations/en'

const localeData = mergeLocaleData({
  en: { ...enData, ...payUiEnLocaleData },
})

export type SupportedLangCode = keyof typeof localeData

export default localeData
