import type { Site } from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, post } from '@helpers/xhr'

const { api } = appConfig

export type SitesResponse = Partial<Record<string, Site>>

type AddMarketplace = {
  id: string
  siteAbb: string
}

type AddMarketplaceDataResponse = {
  refreshToken: string
  siteAbb?: string | null | undefined
  isComplete: boolean
  inReview: boolean
}

export default {
  async getSites() {
    const { data: response } = await get<SitesResponse>(
      api.accounts.sites.getSites
    )
    return response
  },
  async getSite({ siteAbb }: Site) {
    return (await this.getSites())[siteAbb as string]
  },
  // blocked by ZRO-2144
  async addMarketplace({ id, siteAbb }: AddMarketplace) {
    const response = await post<AddMarketplaceDataResponse>(
      api.accounts.sites.addMarketplace,
      { siteAbb },
      { entities: { id } }
    )
    return response
  },
}
