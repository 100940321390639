import type { AxiosError } from 'axios'
import type { History } from 'history'
import jwtDecode from 'jwt-decode'
import { put, takeLatest, takeLeading, call } from 'redux-saga/effects'

import { ddLogger, showSnackbar } from '@b-stock/bstock-react'

import accountsApi from '@api/account'
import authApi from '@api/auth'
import notification from '@api/notification'
import personApi from '@api/person'
import RolesApi from '@api/roles'
import signUpApi from '@api/signUp'
import type { JWTAccessToken } from '@helpers/accessToken'
import { refreshAccessToken } from '@helpers/accessToken'
import CookieManager from '@helpers/CookieManager'
import { getSignUpState } from '@helpers/getSignUpState'
import { gtag, gtagFields } from '@helpers/gtag'
import { sanitizeEmail } from '@helpers/sanitize'
import {
  accessTokenFulfilled,
  accessTokenPending,
  accessTokenRejected,
} from '@store/auth/login/actions'
import { setAuthCookies } from '@store/auth/login/saga'

import type {
  CheckEmailActionPayload,
  SubmitEmailActionPayload,
  SetPasswordActionPayload,
  FetchVerificationCodeActionPayload,
} from './actions'
import {
  checkEmailPending,
  checkEmailFulfilled,
  checkEmailRejected,
  submitEmailPending,
  submitEmailFulfilled,
  submitEmailRejected,
  setPasswordPending,
  setPasswordFulfilled,
  setPasswordRejected,
  fetchVerificationCodeAction,
  fetchVerificationCodePending,
  fetchVerificationCodeFulfilled,
  fetchVerificationCodeRejected,
} from './actions'
import types from './types'

const { submitEmail, fetchVerificationCode } = signUpApi

export function* checkEmailSaga({
  payload,
}: {
  payload: CheckEmailActionPayload
}) {
  try {
    yield put(checkEmailPending())
    const { email } = payload
    yield put(
      checkEmailFulfilled({
        email: sanitizeEmail(email),
      })
    )
  } catch (error: any) {
    ddLogger.error('check email error', {}, error)
    yield put(checkEmailRejected(error))
  }
}

export function* submitEmailSaga({
  payload,
}: {
  payload: SubmitEmailActionPayload
}) {
  const { email, id } = payload
  const sanitizedEmail = sanitizeEmail(email)
  const { siteAbb } = getSignUpState()

  try {
    yield put(submitEmailPending())

    let payload
    // person exists, resend email verification code
    if (id) {
      yield put(
        fetchVerificationCodeAction({
          exists: true,
          hasCredentials: false,
          isEmailVerified: false,
          id,
        })
      )
      payload = { _id: id, email: sanitizedEmail, isEmailVerified: false }
    } else {
      // otherwise create person (email verification code sent automatically)
      const accountData: Awaited<ReturnType<typeof submitEmail>> = yield call(
        submitEmail,
        { email: sanitizedEmail, ...(siteAbb ? { siteAbb } : {}) }
      )

      // TODO: remove logging of email when no longer needed for debugging
      if (siteAbb) {
        ddLogger.info(`submitting email data to sign up for site ${siteAbb}`, {
          siteAbb,
          email: sanitizedEmail,
          target: {
            accountId: accountData.data.accountId,
            personId: accountData.data._id,
            siteAbb,
          },
        })
      } else {
        ddLogger.info('submitted email data without a site abbreviation', {
          email: sanitizedEmail,
          target: {
            accountId: accountData.data.accountId,
            personId: accountData.data._id,
          },
        })
      }
      payload = accountData?.data
    }

    yield put(submitEmailFulfilled(payload))
  } catch (error: any) {
    ddLogger.error('Callback catch to toast error', {}, error)
    yield put(submitEmailRejected(error))
  }
}

export function* fetchVerificationCodeSaga({
  payload,
}: {
  payload: FetchVerificationCodeActionPayload
}) {
  const { exists, hasCredentials, isEmailVerified, id, formatMessage, email } =
    payload

  try {
    yield put(fetchVerificationCodePending())

    if (!id) {
      if (formatMessage) {
        yield call(showSnackbar, {
          content: formatMessage('resendErrorId'),
          options: { type: 'error' },
        })
      }
      throw new Error('cannot fetch verification code without id')
    }

    // Is email is existing or already verified
    if (exists === false || hasCredentials || isEmailVerified) {
      if (formatMessage) {
        yield call(showSnackbar, {
          content: formatMessage('resendEmailExist'),
          options: { type: 'error' },
        })
      }
      return
    }

    yield call(fetchVerificationCode, { id })
    yield put(fetchVerificationCodeFulfilled())
    if (email && formatMessage) {
      yield call(showSnackbar, {
        content: formatMessage({ id: 'resend', email }),
        options: { type: 'success' },
      })
    }
  } catch (error: any) {
    ddLogger.error('Fetch verification code error', {}, error)
    const errorPayload = error?.response?.status
      ? { status: error.response.status }
      : { message: error.message }
    if (formatMessage) {
      yield call(showSnackbar, {
        content: formatMessage({ id: 'resendError', error }),
        options: { type: 'error' },
      })
    }
    yield put(fetchVerificationCodeRejected(errorPayload))
  }
}

export function* setUserData({
  jwtData,
  payload,
  history,
}: {
  jwtData: JWTAccessToken
  payload: SetPasswordActionPayload
  history: History
}) {
  try {
    yield put(accessTokenPending())

    const {
      country,
      brid,
      firstName,
      lastName,
      phone,
      acceptMarketingSolicitation = false,
      redirectAfterSignup,
      subflow,
    } = payload

    yield call(RolesApi.create, {
      id: jwtData.accountId,
      role: 'buyer',
      data: { status: 'INTENDED', reason: 'SELF_ONBOARDING' },
    })

    yield call(
      refreshAccessToken,
      CookieManager.getCookie('bstock_access_token')!,
      CookieManager.getCookie('bstock_refresh_token')!
    )

    if (!brid) {
      ddLogger.warn(
        'Updating business verification data in setUserData, but no brid value available',
        { target: { accountId: jwtData.accountId, personId: jwtData.personId } }
      )
    }

    if (!country) {
      ddLogger.warn(
        'Updating business verification data in setUserData, but no country value available',
        { target: { accountId: jwtData.accountId, personId: jwtData.personId } }
      )
    }

    yield call(accountsApi.saveAccount, {
      data: {
        id: jwtData.accountId,
        business: {
          verification: {
            country: country?.toUpperCase(),
            brid,
          },
        },
      },
    })
    yield call(personApi.updatePerson, {
      id: jwtData.personId,
      data: { firstName, lastName, phone },
    })
    if (!acceptMarketingSolicitation) {
      // only call when false to unsubscribe (everyone is default subscribed...
      // in fact error occurs to call with true as already subscribed)
      yield call(
        notification.updateMarketingPreference,
        acceptMarketingSolicitation,
        { personId: jwtData.personId }
      )
    }

    const {
      data: account,
    }: Awaited<ReturnType<typeof authApi.accessTokenCheck>> = yield call(
      authApi.accessTokenCheck,
      jwtData.personId
    )
    yield put(
      accessTokenFulfilled({
        ...account,
        personId: jwtData.personId,
      })
    )
    // If MAO subflow, skip profile creation and redirect to target /buy/ URL
    if (subflow === 'mao') {
      if (!redirectAfterSignup.includes('/buy/')) {
        history.push('/error')
        return
      }
      window.location.assign(redirectAfterSignup)
      return
    }
    history.push('/create-profile')
  } catch (error) {
    yield put(accessTokenRejected(error))
  }
}

export function* setPasswordSaga({
  payload,
  history,
}: {
  payload: SetPasswordActionPayload
  history: History
}) {
  try {
    yield put(setPasswordPending())

    const { id, password, siteAbb, verificationCode } = payload
    const { data } = yield call(signUpApi.setPassword, {
      peopleId: id,
      verificationCode,
      oldPassword: '',
      newPassword: password,
    })
    ddLogger.info('User set password during registration', {
      target: { personId: id, siteAbb },
    })

    yield put(setPasswordFulfilled())
    gtag({
      event: gtagFields.event.login,
      website_flow: gtagFields.website_flow,
      method: gtagFields.method.fusionAuth,
      ...(siteAbb ? { marketplace: siteAbb } : {}),
    })

    const jwtData = jwtDecode<JWTAccessToken>(data.accessToken)

    setAuthCookies({
      accountId: jwtData.accountId,
      access_token: data.accessToken,
      refresh_token: data.refreshToken,
    })

    yield call(setUserData, {
      jwtData,
      payload,
      history,
    })
  } catch (error) {
    ddLogger.error('Failed to set password during registration', {
      error: {
        message: (error as Error)?.message ?? null,
        statusCode: (error as AxiosError)?.response?.status ?? null,
      },
      target: {
        personId: payload.id,
        email: payload.email,
      },
    })
    yield put(setPasswordRejected(error))
  }
}

export default function* signupSaga() {
  yield takeLatest(types.CHECK_EMAIL, checkEmailSaga as any)
  // `takeLeading` here so that if the user manages to mash click/enter/whatever
  // and fires off two submit email actions in quick succession, the second (and
  // any subsequent) one is discarded
  yield takeLeading(types.SUBMIT_EMAIL, submitEmailSaga as any)
  yield takeLatest(
    types.FETCH_VERIFICATION_CODE,
    fetchVerificationCodeSaga as any
  )
  yield takeLatest(types.SET_PASSWORD, setPasswordSaga as any)
}
