import loadable from '@loadable/component'

import { SpinnerPage } from '@components/ui/spinner/Spinner'

const options = {
  fallback: <SpinnerPage />,
}

const auth = {
  SignUpPage: loadable(
    () =>
      import(/* webpackChunkName: "SignUpPage" */ '@pages/auth/signUp/Page'),
    options
  ),
  OAuth2RedirectPage: loadable(
    () =>
      import(
        /* webpackChunkName: "LoginPage" */ '@pages/auth/login/OAuth2RedirectPage'
      ),
    options
  ),
  SignInPage: loadable(
    () =>
      import(/* webpackChunkName: "SignInPage" */ '@pages/auth/signIn/Page'),
    options
  ),
}

const businessProfile = {
  BusinessProfileSummaryPage: loadable(
    () =>
      import(
        /* webpackChunkName: "BusinessProfileSummaryPage" */ '@pages/businessProfile/summary/Page'
      ),
    options
  ),
  BusinessProfileSettingsPage: loadable(
    () =>
      import(
        /* webpackChunkName: "BusinessProfileSummaryPage" */ '@pages/settings/businessSettings/Page'
      ),
    options
  ),
  BusinessProfileBuyingEligibility: loadable(
    () =>
      import(
        /* webpackChunkName: "BusinessProfileSummaryPage" */ '@pages/businessProfile/buyingEligibility/Page'
      ),
    options
  ),
  BusinessProfileBackgroundPage: loadable(
    () =>
      import(
        /* webpackChunkName: "BusinessProfileBackgroundPage" */ '@pages/businessProfile/background/Page'
      ),
    options
  ),
  FreightForwarderPage: loadable(
    () =>
      import(
        /* webpackChunkName: "FreightForwarder" */ '@pages/businessProfile/freightForwarder/Page'
      ),
    options
  ),
  BusinessProfileTaxIdPage: loadable(
    () =>
      import(
        /* webpackChunkName: "BusinessProfileTaxIdPage" */ '@pages/businessProfile/taxId/Page'
      ),
    options
  ),
  PaymentMethod: loadable(
    () =>
      import(
        /* webpackChunkName: "PaymentMethodPage" */ '@pages/businessProfile/payment/paymentMethod/Page'
      ),
    options
  ),
  Transactions: loadable(
    () =>
      import(
        /* webpackChunkName: "PaymentMethodPage" */ '@pages/businessProfile/payment/transactions/Page'
      ),
    options
  ),
  AddressBook: loadable(
    () =>
      import(
        /* webpackChunkName: "AddressBookPage" */ '@pages/businessProfile/addressBook/Page'
      ),
    options
  ),
  PersonalSettings: loadable(
    () =>
      import(
        /* webpackChunkName: "PersonalSettingsPAge" */ '@pages/businessProfile/personalSettings/Page'
      ),
    options
  ),
}

const notificationPreferences = {
  SmsNotificationPage: loadable(
    () =>
      import(
        /* webpackChunkName: "SmsNotificationPage" */ '@pages/settings/notificationPreferences/components/SmsPreferences'
      ),
    options
  ),
  EmailNotificationPage: loadable(
    () =>
      import(
        /* webpackChunkName: "EmailNotificationPage" */ '@pages/settings/notificationPreferences/components/EmailPreferences'
      ),
    options
  ),
}

const settings = {
  MarketplaceEligibilityPage: loadable(
    () =>
      import(
        /* webpackChunkName: "MarketplaceEligibilityPage" */ '@pages/settings/marketplaceEligibility/eligibility/Page'
      ),
    options
  ),
  MarketplaceRestrictionPage: loadable(
    () =>
      import(
        /* webpackChunkName: "MarketplaceRestrictionsPage" */ '@pages/settings/marketplaceRestrictions/restrictions/Page'
      ),
    options
  ),
  SettingsPage: loadable(
    () => import(/* webpackChunkName: "SettingsPage" */ '@pages/settings/Page'),
    options
  ),
  CreateBusinessProfile: loadable(
    () =>
      import(
        /* webpackChunkName: "CreateBusinessProfilePage" */ '@pages/settings/businessSettings/components/Page'
      ),
    options
  ),
  ListingSettingsPage: loadable(
    () =>
      import(
        /* webpackChunkName: "ListingSettingsPage" */ '@pages/listingSettings/Page'
      ),
    options
  ),
  SubscriptionManagementPage: loadable(
    () =>
      import(
        /* webpackChunkName: "SubscriptionManagementPage" */ '@pages/subscriptionManagement/Page'
      ),
    options
  ),
  NotificationPreferencesPage: loadable(
    () =>
      import(
        /* webpackChunkName: "NotificationPreferencesPage" */ '@pages/settings/notificationPreferences/Page'
      ),
    options
  ),
}

const Components = {
  ...auth,
  ...businessProfile,
  ...notificationPreferences,
  ...settings,

  HomePage: loadable(
    () => import(/* webpackChunkName: "HomePage" */ '@pages/home/Page'),
    options
  ),

  CreateProfilePage: loadable(
    () =>
      import(
        /* webpackChunkName: "CreateProfilePage" */ '@pages/createProfile/Page'
      ),
    options
  ),

  SuccessPage: loadable(
    () =>
      import(
        /* webpackChunkName: "CreateProfilePage" */ '@pages/businessProfile/success/Page'
      ),
    options
  ),

  SpecialDocsPage: loadable(
    () =>
      import(
        /* webpackChunkName: "SpecialDocsPage" */ '@pages/specialDocs/Page'
      ),
    options
  ),
  AddressBookDetailsPage: loadable(
    () =>
      import(
        /* webpackChunkName: "AddressBookPage" */ '@pages/businessProfile/addressBook/addressBookDetails/AddressBookDetails'
      ),
    options
  ),

  NoMatchPage: loadable(
    () =>
      import(
        /* webpackChunkName: "NoMatchPage" */ '@pages/nomatchpage/NoMatchPage'
      ),
    options
  ),
}

export default Components
