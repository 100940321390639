import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { RootState } from '@store/rootReducer'

type UserInfo = {
  accountId?: string
  personId?: string
}

const useUserInfo = (): UserInfo => {
  const params: UserInfo = useParams()
  const { accountId: storeAccountId, personId: storePersonId } = useSelector(
    ({ auth }: RootState) => auth.login
  )

  const accountId = params.accountId || storeAccountId!
  const personId = params.personId || storePersonId!

  return useMemo(
    () => ({
      accountId,
      personId,
    }),
    [accountId, personId]
  )
}

export default useUserInfo
