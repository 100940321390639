import type { SupportedLangCode } from './localeData'

const defaultLang: SupportedLangCode = 'en'

const supportedLangCodes: SupportedLangCode[] = ['en']

export function determineUserLang(
  acceptedLangs: string | string[]
): SupportedLangCode {
  if (typeof acceptedLangs === 'string') {
    acceptedLangs = [acceptedLangs]
  }

  const acceptedLangCodes = acceptedLangs.map(stripCountry)
  const language = getLangCode(acceptedLangs, acceptedLangCodes)

  return language
}

function stripCountry(lang: string) {
  return lang.trim().replace('_', '-').split('-')[0]
}

function getLangCode(acceptedLangs: string[], acceptedLangCodes: string[]) {
  for (const [index, code] of acceptedLangCodes.entries()) {
    const supported = supportedLangCodes.find(
      (value) => value === code || value === acceptedLangs[index]
    )
    if (supported) {
      return supported
    }
  }

  return defaultLang
}
