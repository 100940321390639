import type { ParsedQuery } from 'query-string'
import queryString from 'query-string'

/**
 * query-string parse's ParsedQuery uses null,
 * Next router's ParsedUrlQuery uses undefined
 */
export type Query = {
  [key: string]: string | string[] | undefined | null
}

/**
 * Get string key value from a Query object, like
 *   1. ParsedUrlQuery fron Next router, or
 *   2. ParsedQuery from query-string parse
 *   3. Query string, e.g. '?foo=bar&spam=eggs'
 *
 * If string, return it.
 * If array, return first element.
 * If undefined, return undefined.
 * If null, return undefined.
 */
const getSingleParam = (
  input: Query | ParsedQuery | string,
  key: string
): string | null => {
  const query = typeof input === 'string' ? queryString.parse(input) : input
  const value = query[key]
  if (typeof value === 'string') return value
  if (Array.isArray(value)) return value[0]
  return null
}

/**
 * "Flatten" query, i.e. convert array values to single values, value[0].
 * Omit null and undefined params.
 */
export const getSingleParams = (
  input: Query | string
): Partial<Record<string, string>> => {
  const query = typeof input === 'string' ? queryString.parse(input) : input
  const result: Record<string, string> = {}
  for (const key in query) {
    const value = getSingleParam(query, key)
    if (value !== null) result[key] = value
  }
  return result
}
