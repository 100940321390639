import { call, put, takeLatest, select } from 'redux-saga/effects'

import { ddLogger } from '@b-stock/bstock-react'

import authApi from '@api/auth'

import { pending, reject, fill } from './actions'
import types from './types'

function* changePasswordSaga({ payload }) {
  try {
    const account = yield select((store) => store.auth.login)

    yield put(pending())
    const { data } = yield call(
      authApi.changePassword,
      account.personId,
      payload
    )

    yield put(fill(data))
  } catch (error) {
    ddLogger.error('Change password error', {}, error)
    yield put(reject(error))
  }
}

export default function* authSaga() {
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga)
}
