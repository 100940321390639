import makeReducer, { asyncReducer } from '@helpers/makeReducer'

import types from './types'

export const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
}

const parseError = () => {}

export default makeReducer(INITIAL_STATE, {
  ...asyncReducer({
    types: {
      pending: types.CHANGE_PASSWORD_PENDING,
      reset: types.CHANGE_PASSWORD_RESET,
      rejected: types.CHANGE_PASSWORD_REJECTED,
    },
    parseError,
    onPending: () => ({
      success: null,
      error: null,
    }),
    parsePayload: () => ({
      success: true,
    }),
  }),
  [types.CHANGE_PASSWORD_FULLFILLED]: (state, data) => ({
    ...state,
    loading: false,
    error: null,
    success: data.payload,
  }),
})
