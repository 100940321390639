import { combineReducers } from 'redux'

import checkEmail from './reducers/checkEmail'
import fetchVerificationCode from './reducers/fetchVerificationCode'
import setPassword from './reducers/setPassword'
import signUpEmail from './reducers/signUpEmail'
import submitEmail from './reducers/submitEmail'

export default combineReducers({
  submitEmail,
  signUpEmail,
  checkEmail,
  fetchVerificationCode,
  setPassword,
})
