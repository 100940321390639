import type { AsyncReducerState } from '@helpers/makeReducer'
import makeReducer, {
  asyncReducer,
  INITIAL_ASYNC_REDUCER_STATE,
} from '@helpers/makeReducer'

import types from '../types'

export default makeReducer<AsyncReducerState>(
  INITIAL_ASYNC_REDUCER_STATE,
  asyncReducer({
    types: {
      pending: types.FETCH_VERIFICATION_CODE_PENDING,
      fulfilled: types.FETCH_VERIFICATION_CODE_FULFILLED,
      rejected: types.FETCH_VERIFICATION_CODE_REJECTED,
    },
  })
)
