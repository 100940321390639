import cloneDeep from 'lodash-es/cloneDeep'
import sortBy from 'lodash-es/sortBy'
import * as Yup from 'yup'

import type { DaySchedule } from '@b-stock/location-api-client'

type ValidateResult = {
  slotIndexes: number[]
  isValid: boolean
}

export const validateTimeslots = (timeslots: DaySchedule[]): ValidateResult => {
  const sortedTimeslot = sortBy(cloneDeep(timeslots), ['from'])

  const res: ValidateResult = {
    slotIndexes: [],
    isValid: true,
  }

  for (let i = 0; i < sortedTimeslot.length; i++) {
    const start = parseInt(sortedTimeslot[i].from)
    const end = parseInt(sortedTimeslot[i].to)
    const validDuration = start < end

    const nextStart = sortedTimeslot[i + 1]
      ? parseInt(sortedTimeslot[i + 1]?.from)
      : undefined

    if (!validDuration) {
      res.slotIndexes.push(i)
      res.isValid = false
    }

    if (validDuration && nextStart && end > nextStart) {
      res.slotIndexes.push(i)
      res.isValid = false
    }
  }

  return res
}

Yup.addMethod(Yup.array, 'timeslot', function (fieldName: string) {
  const valueTest = (value: any, ctx: any): boolean => {
    const validationResult = validateTimeslots(value)
    if (!validationResult.isValid) {
      return ctx.createError({
        message: validationResult.slotIndexes.join('.'),
      })
    }
    return true
  }

  return this.when([fieldName], {
    is: (checked: boolean) => checked,
    then: () => this.test(valueTest),
    otherwise: () => this.optional().nullable(),
  })
})

declare module 'yup' {
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
    T = unknown,
    C = unknown,
    TOut = unknown,
  > {
    timeslot(
      fieldName: string,
      errorMesage: string
    ): Yup.ArraySchema<TIn, TContext, TDefault, TFlags, T, C, TOut>
  }
}
