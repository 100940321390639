import type * as React from 'react'

import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { Spinner as BStockSpinner } from '@b-stock/bstock-react'

const SpinnerPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

type SpinnerProps = Omit<
  React.ComponentPropsWithoutRef<typeof BStockSpinner>,
  'text'
> & {
  text?: boolean
}

const Spinner = ({ text = true, ...props }: SpinnerProps) => {
  const { formatMessage } = useIntl()
  return (
    <BStockSpinner
      {...props}
      text={text ? formatMessage({ id: 'loading' }) : ''}
    />
  )
}

export default Spinner

export const SpinnerPage = () => (
  <SpinnerPageContainer>
    <Spinner />
  </SpinnerPageContainer>
)
