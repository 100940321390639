import type { History } from 'history'

import rootRoutes from './rootRoutes'
import Router from './Router'

let lastLocation: any = null

export const handleHistoryPush =
  (prevHistoryPush: any) =>
  (pathname: string, state = {}) => {
    if (
      lastLocation === null ||
      pathname !==
        `${lastLocation.pathname}${lastLocation.search}${lastLocation.hash}` ||
      JSON.stringify(state) !== JSON.stringify(lastLocation.state)
    ) {
      prevHistoryPush(pathname, state)
    }
  }

export const HandlePageConditionalRender = ({
  history,
  component,
}: {
  history: History
  component?: React.ReactNode
}): React.JSX.Element => {
  // prevent react router push same route to history stack
  history.listen((location: any) => (lastLocation = location))
  const prevHistoryPush = history.push
  history.push = handleHistoryPush(prevHistoryPush)

  return <>{component}</>
}

const Routes = () => <Router routes={rootRoutes} />

export default Routes
