export default Object.freeze({
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGOUT_PENDING: 'AUTH_LOGOUT_PENDING',
  AUTH_LOGOUT_FULFILLED: 'AUTH_LOGOUT_FULFILLED',
  AUTH_LOGOUT_REJECTED: 'AUTH_LOGOUT_REJECTED',
  AUTH_PENDING: 'AUTH_PENDING',
  AUTH_FULFILLED: 'AUTH_FULFILLED',
  AUTH_REJECTED: 'AUTH_REJECTED',
  AUTH_LOADED: 'AUTH_LOADED',
  AUTH_RESET: 'AUTH_RESET',
  AUTH_FORM_VISIBLE: 'AUTH_FORM_VISIBLE',
  AUTH_MIGRATION_PENDING: 'AUTH_MIGRATION_PENDING',
  AUTH_CLEAR_ERRORS: 'AUTH_CLEAR_ERRORS',
  AUTH_REDIRECT: 'AUTH_REDIRECT',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  ACCESS_TOKEN_PENDING: 'ACCESS_TOKEN_PENDING',
  ACCESS_TOKEN_FULFILLED: 'ACCESS_TOKEN_FULFILLED',
  ACCESS_TOKEN_REJECTED: 'ACCESS_TOKEN_REJECTED',
  AUTH_REFRESH_ACCESS_TOKEN: 'AUTH_REFRESH_ACCESS_TOKEN',
  AUTH_REFRESH_ACCESS_TOKEN_PENDING: 'AUTH_REFRESH_ACCESS_TOKEN_PENDING',
  AUTH_REFRESH_ACCESS_TOKEN_FULFILLED: 'AUTH_REFRESH_ACCESS_TOKEN_FULFILLED',
  AUTH_REFRESH_ACCESS_TOKEN_REJECTED: 'AUTH_REFRESH_ACCESS_TOKEN_REJECTED',
  ACCESS_TOKEN_CHECK: 'ACCESS_TOKEN_CHECK',
  ACCESS_TOKEN_CHECK_PENDING: 'ACCESS_TOKEN_CHECK_PENDING',
  ACCESS_TOKEN_CHECK_FULFILLED: 'ACCESS_TOKEN_CHECK_FULFILLED',
  ACCESS_TOKEN_CHECK_REJECTED: 'ACCESS_TOKEN_CHECK_REJECTED',
  ACTIVATE_BUYER_ROLE: 'ACTIVATE_BUYER_ROLE',
})
