import isArray from 'lodash-es/isArray'
import * as Yup from 'yup'

type stepType = string | number | null

Yup.addMethod(
  Yup.mixed,
  'forSteps',
  function (
    steps,
    schema,
    testOptions = { test: () => true },
    requiredMessage
  ) {
    return this.when('$step', {
      is: (step: stepType) =>
        isArray(steps) ? steps.includes(step) : steps === step,
      then: () => (schema || this).test(testOptions).required(requiredMessage),
      otherwise: () => this.optional().nullable(),
    })
  }
)

Yup.addMethod(Yup.mixed, 'requiredInStates', function (states) {
  return this.when('state', {
    is: (state: string) => states.includes(state),
    then: () => this.required(),
    otherwise: () => this.optional().nullable(),
  })
})

declare module 'yup' {
  interface StringSchema {
    forSteps(
      step: stepType | stepType[],
      schema?: Yup.AnySchema,
      testOptions?: {
        test: Yup.TestConfig
      },
      requiredMessage?: string
    ): Yup.StringSchema
  }

  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
    T = unknown,
    C = unknown,
    TOut = unknown,
  > {
    forSteps(
      step: stepType | stepType[],
      schema?: Yup.AnySchema,
      testOptions?: {
        test: Yup.TestConfig
      },
      requiredMessage?: string
    ): Yup.ArraySchema<TIn, TContext, TDefault, TFlags, T, C, TOut>
  }
}
