import * as Yup from 'yup'

import { SchedulePreference } from '@b-stock/location-api-client'

import type { CheckedDaysType } from '@pages/businessProfile/addressBook/addAddressForm/shared.d'

Yup.addMethod(Yup.string, 'schedulingPolicy', function (errorMesage) {
  const valueTest = (value: any, ctx: any): boolean => {
    const valid = value === SchedulePreference.ContactFirst
    if (!valid) {
      return ctx.createError({ message: errorMesage })
    }
    return true
  }

  return this.when(['checked'], {
    is: (checked: CheckedDaysType) => !Object.values(checked).find((el) => el),
    then: () => this.test(valueTest),
    otherwise: () => Yup.string().required(),
  })
})

declare module 'yup' {
  interface StringSchema {
    schedulingPolicy(errorMesage: string): Yup.StringSchema
  }
}
