import changePassword from './changePassword/types'
import login from './login/types'
import signIn from './signIn/types'
import signUp from './signUp/types'

export default {
  login,
  changePassword,
  signIn,
  signUp,
}
