import { getSingleParams } from '@helpers/getSingleParams'
import type { SignUpState } from '@pages/auth/signUp/shared/types'

const defaultState = { values: {} }

/**
 * @returns SignUpState from sessionStorage, with querystring overrides
 */
export const getSignUpState = (): SignUpState => ({
  ...defaultState,
  ...JSON.parse(sessionStorage.getItem('BStock::AP::SignUp') || '{}'),
  ...getSingleParams(window.location.search),
})
