import type { Person } from '@b-stock/account-api-client'

import type { AsyncReducerState } from '@helpers/makeReducer'
import makeReducer, {
  asyncReducer,
  INITIAL_ASYNC_REDUCER_STATE,
} from '@helpers/makeReducer'

import types from './types'

export type LoginData = Partial<Person> & {
  personId?: string
}

type LoginState = AsyncReducerState &
  LoginData & {
    isLoggedIn: boolean
    isMigrating: boolean
    siteStatus: string
  }

export const INITIAL_STATE: LoginState = {
  ...INITIAL_ASYNC_REDUCER_STATE,
  isLoggedIn: false,
  isMigrating: false,
  accountId: '',
  siteStatus: '',
  firstName: '',
  lastName: '',
  email: '',
}

export default makeReducer(INITIAL_STATE, {
  ...asyncReducer({
    types: {
      pending: types.AUTH_PENDING,
      fulfilled: types.AUTH_FULFILLED,
      rejected: types.AUTH_REJECTED,
    },
    parsePayload: (payload) => ({
      ...payload,
      isMigrating: false,
      isLoggedIn: true,
    }),
  }),

  ...asyncReducer({
    types: {
      pending: types.ACCESS_TOKEN_PENDING,
      fulfilled: types.ACCESS_TOKEN_FULFILLED,
      rejected: types.ACCESS_TOKEN_REJECTED,
    },
  }),

  [types.AUTH_MIGRATION_PENDING]: (state) => ({
    ...state,
    isMigrating: true,
  }),

  [types.AUTH_CLEAR_ERRORS]: (state) => ({
    ...state,
    error: null,
  }),

  [types.AUTH_RESET]: () => INITIAL_STATE,

  // [types.AUTH_REFRESH_ACCESS_TOKEN_PENDING]: (state) => ({
  //   ...state,
  // }),
})
